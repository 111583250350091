import React, { createContext, useContext, useMemo, useState } from 'react';

export const GTMContext = createContext();

export const GTMContextProvider = ({ children }) => {
  const [isPageInitializationEventSent, setIsPageInitializationEventSent] = useState(false);

  return (
    <GTMContext.Provider
      value={useMemo(
        () => ({
          isPageInitializationEventSent,
          setIsPageInitializationEventSent,
        }),
        [isPageInitializationEventSent]
      )}
    >
      {children}
    </GTMContext.Provider>
  );
};

export const useGtmContext = () => {
  return useContext(GTMContext);
};
