import { useEffect, useRef } from 'react';

import dayjs from 'dayjs';

import { useContentContext } from '@context/content';
import { useGtmContext } from '@context/gtm';
import { useLocale } from '@hooks/useLocale';
import { useUser } from '@hooks/user/useUser';
import { buildPath } from '@utils/paths/build-paths';

import { sendPageInitializationEvent } from '@services/gtm/page-initialization';

export const getSitesVisitedCount = () => {
  const isBrowser = typeof window !== 'undefined';

  if (!isBrowser || !localStorage) {
    return;
  }

  const sitesVisitedCount = localStorage.getItem('sitesVisited');
  const sitesVisitedLastSavedDate = localStorage.getItem('sitesVisitedCounterLastSavedDate');
  const currentDate = dayjs().format('MM-DD-YYYY');

  if (currentDate !== sitesVisitedLastSavedDate) {
    localStorage.setItem('sitesVisited', '1');
    localStorage.setItem('sitesVisitedCounterLastSavedDate', currentDate);

    return 1;
  }

  const parsedVisitedCounter = Number.parseInt(sitesVisitedCount, 10) || 0;

  localStorage.setItem('sitesVisited', `${parsedVisitedCounter + 1}`);
  localStorage.setItem('sitesVisitedCounterLastSavedDate', currentDate);

  return parsedVisitedCounter + 1;
};

const defaultLanguageForCountry = {
  de: 'de',
  nl: 'nl',
};

export const usePageInitializationEvent = () => {
  const isEventAlreadySent = useRef(false);
  const { data: user, isIdle: isIdleUser } = useUser();
  const { country, language } = useLocale();
  const { currentPageId, locale } = useContentContext();
  const { setIsPageInitializationEventSent } = useGtmContext();

  const sendEvent = () =>
    sendPageInitializationEvent({
      language,
      country,
      user,
      pageCount: getSitesVisitedCount(),
      page: {
        type: currentPageId,
        url: buildPath({ pageId: currentPageId, locale }),
      },
    });

  useEffect(() => {
    if (isIdleUser && !isEventAlreadySent.current) {
      isEventAlreadySent.current = true;
      sendEvent();
      setIsPageInitializationEventSent(true);
    }

    if (user && !isEventAlreadySent.current) {
      isEventAlreadySent.current = true;
      sendEvent();
      setIsPageInitializationEventSent(true);
    }
  }, [user]);
};
