import { mapCartItemsAsGtmItem, mapSubscriptionToGtmItem, pushGtmAsyncEvent, pushGtmEvent } from './gtm';
import { mapCartItemsWithOfferAsGtmItem } from './gtm-offer';

import { splitLocale } from '@services/locale-config';

const taxRate = {
  nl: 21,
  de: 19,
};

const USER_STATE_TO_GA_ACTION = {
  created: 'User Create',
  loaded: 'User Loaded',
  identified: 'User Identified',
  restored: 'User Restored',
};

export const currentAbTestsList = abTests => {
  const event = {
    userTestList: abTests.map(test => ({ abTestName: test.name, abTestVariationName: test.variant })),
  };

  pushGtmEvent(event);
};

export const exposedToAbTest = abTest => {
  const event = {
    event: 'AB test: exposed to variation',
    abTestName: abTest.name,
    abTestVariationName: abTest.variant,
  };

  pushGtmEvent(event);
};

export const assignedToAbTestGroup = abTest => {
  const event = {
    event: 'AB test: assigned to group',
    abTestName: abTest.name,
    abTestVariationName: abTest.variant,
  };

  pushGtmEvent(event);
};

export const listenForFreshChatEvents = fcWidget => {
  fcWidget.on('widget:loaded', () => {
    fcWidget.on('widget:opened', () => {
      pushGtmEvent({ event: 'freshchat event', fc_action: 'Widget Open' });
    });
    fcWidget.on('widget:closed', () => {
      pushGtmEvent({ event: 'freshchat event', fc_action: 'Widget Close' });
    });
    fcWidget.on('message:received', () => {
      pushGtmEvent({ event: 'freshchat event', fc_action: 'Received' });
    });
    fcWidget.on('message:sent', () => {
      pushGtmEvent({ event: 'freshchat event', fc_action: 'Sent' });
    });

    fcWidget.on('user:statechange', response => {
      const action = response && response.data && USER_STATE_TO_GA_ACTION[response.data.userState];
      if (action) {
        pushGtmEvent({ event: 'freshchat event', fc_action: action });
      }
    });
  });
};

export const sendPaymentEvent = async ({ payment, cart, country, plans }) => {
  const items = mapCartItemsWithOfferAsGtmItem(cart, plans);
  const summarizedContractValue = cart.items?.reduce((acc, next) => {
    return next.pricing.amount * next.pricing.months + acc;
  }, 0);
  const sumAfterTax = summarizedContractValue * ((100 - taxRate[country]) / 100);
  const totalDepositPrice = cart.items?.reduce((acc, cartItem) => cartItem.offerItem?.plan?.depositAmount + acc, 0);
  const event = {
    event: 'transaction_complete',
    id: payment.id,
    value: summarizedContractValue / 100,
    tax: (summarizedContractValue - sumAfterTax) / 100,
    paymentStatus: payment.status,
    deposit: totalDepositPrice / 100 || null,
    items,
    coupon: cart?.coupon?.code,
  };

  return pushGtmAsyncEvent(event);
};

export const sendCheckoutEvent = ({ step, option, cart, plans }) => {
  const items = mapCartItemsWithOfferAsGtmItem(cart, plans);

  pushGtmEvent({
    event: 'checkout',
    step,
    option,
    items,
  });
};

export const sendCreditCheckEvent = async ({
  riskResult,
  option,
  locale,
  variant = 'checkout',
  shouldShowFailedRequestPage,
  isProviderError,
  isWrongPersonalDataError,
}) => {
  const { country } = splitLocale(locale);
  const { status } = riskResult;
  const providers = {
    nl: 'Focum',
    de: 'Boniversum',
  };

  const gtmStatuses = {
    REQUIRE_ID_CHECK: 'approved',
    ALLOWED_TO_CONTINUE: 'skipped',
    DENIED_TO_CONTINUE: 'declined',
  };

  if (isWrongPersonalDataError) {
    return pushGtmAsyncEvent({
      event: 'credit_check_error',
      status: 'refill form',
      variant,
      field: riskResult.details.fields[0],
      option: {
        ...option,
        provider: providers[country],
      },
    });
  }

  if (shouldShowFailedRequestPage) {
    return pushGtmAsyncEvent({
      event: 'credit_check_error',
      status: 'second server error, try again later',
      variant,
      option: {
        ...option,
        provider: providers[country],
      },
    });
  }

  if (isProviderError) {
    return pushGtmAsyncEvent({
      event: 'credit_check_error',
      status: 'server error',
      variant,
      option: {
        ...option,
        provider: providers[country],
      },
    });
  }

  return pushGtmAsyncEvent({
    event: 'credit_check',
    status: gtmStatuses[status],
    variant,
    option: {
      ...option,
      provider: providers[country],
    },
  });
};

export const sendIdCheckEvent = async ({
  option,
  idCheckResult,
  variant = 'checkout',
  isDeclinedByWrongData,
  isDeclinedByImageQuality,
}) => {
  if (isDeclinedByWrongData) {
    return pushGtmAsyncEvent({
      event: 'id_check_error',
      status: 'refill form',
      reasons: idCheckResult?.reasons,
      variant,
      option: {
        ...option,
        provider: 'Onfido',
      },
    });
  }

  if (isDeclinedByImageQuality) {
    return pushGtmAsyncEvent({
      event: 'id_check_error',
      status: 'image quality error',
      reasons: idCheckResult?.reasons,
      variant,
      option: {
        ...option,
        provider: 'Onfido',
      },
    });
  }

  return pushGtmAsyncEvent({
    event: 'id_check',
    status: idCheckResult?.status,
    variant,
    option: {
      ...option,
      provider: 'Onfido',
    },
  });
};

export const sendSelectedPaymentEvent = async ({ option = {}, oneTimeFee, monthlyFee, status, isRetry = false }) => {
  return pushGtmAsyncEvent({
    event: 'selected_payment_providers',
    oneTimeFee: {
      method: oneTimeFee,
      provider: 'Adyen',
    },
    monthlyFee: {
      method: monthlyFee,
      provider: 'Adyen',
    },
    isRetry,
    status,
    option,
  });
};

export const sendPageInitializationEvent = ({ language, country, user, pageCount, page }) => {
  const gtmUser = user
    ? {
        id: user.id,
        loginStatus: 'logged in',
        created: user.createdDate,
        subscriptionList: mapSubscriptionToGtmItem(user.subscriptions || []),
      }
    : {
        loginStatus: 'logged out',
      };

  const event = {
    event: 'dataLayer initialized',
    language,
    country,
    pageCount,
    page,
    user: gtmUser,
  };

  pushGtmEvent(event);
};
