import * as dayjs from 'dayjs';

import getRuntimeConfig from '@utils/getRuntimeConfig';

import { termToMonths } from '@helpers/terms';
import Logger from '@services/logger';

const isRefurbished = pricing => {
  return pricing?.variant === 'refurbished';
};

export const getGaClientId = () => {
  if (typeof window !== 'object') {
    return '';
  }

  if (!window.ga || !window.ga.getAll || !(window.ga.getAll instanceof Function)) {
    return '';
  }

  const tracker = window.ga.getAll()[0];
  if (!tracker) {
    return '';
  }

  return tracker.get('clientId') || '';
};

export const pushGtmEvent = event => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(event);
};

export const pushGtmAsyncEvent = async event => {
  if (typeof window !== 'object') {
    return '';
  }

  const logger = new Logger();
  logger.setContext('pushGtmAsyncEvent');

  //In local environment we are not connecting to GTM so to avoid waiting 5s we have reduced timeout time. On production we want to wait at most 5s to get response from GTM. In such configuration we can still inspect dataLayer array on local.
  const timeoutTime = getRuntimeConfig('GTM_ID') ? 5000 : 500;

  const eventPromise = new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(`GTM event ${event.event} failed`);
    }, timeoutTime);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...event,
      eventCallback: () => {
        clearTimeout(timeoutId);
        resolve();
      },
      eventTimeout: timeoutTime,
    });
  });

  try {
    await eventPromise;
  } catch (error) {
    logger.error({ message: error.message, params: error });
  }
};

export const mapSubscriptionToGtmItem = subscriptions => {
  return subscriptions.map(sub => {
    const startDate = sub.subscriptionActivationDate && dayjs(sub.subscriptionActivationDate).format('YYYY-MM-DD');
    const endDate =
      sub.subscriptionActivationDate &&
      dayjs(sub.subscriptionActivationDate).add(termToMonths(sub.contractTermPeriod), 'months').format('YYYY-MM-DD');

    return {
      contractId: sub.id,
      monthlyPrice: sub.monthlyAmount / 100,
      productVib: sub.plan.id,
      planCategory: sub.plan.category.name,
      // TODO: after add support for new plans take it from correct place
      refurbished: sub.plan.isRefurbished,
      status: sub.status,
      startDate,
      endDate,
    };
  });
};

export const mapCartItemsAsGtmItem = cart => {
  return cart?.items?.map(item => ({
    planCategory: item?.plan?.category.name,
    monthlyPrice: item?.pricing?.amount / 100,
    refurbished: isRefurbished(item.pricing),
    currency: 'EUR',
    selectedPricing: item.pricing,
    cartCouponCode: cart?.coupon?.code,
    enabled:
      item?.plan?.isOnStock === 'available' ||
      (item?.plan?.isOnStock === 'automatic' && item?.plan?.classifications?.some(c => c.isOnStock)),
    country: item.plan.country,
    groupType: item.plan.category.groupType,
    bmId: item?.plan?.bmId,
    productVib: item.plan.product.vib,
  }));
};
