import {
  Offer,
  OfferContractType,
  OfferItem,
  OfferItemOneTimeRecurringPricing,
  OfferItemPricing,
} from 'types/offer.types';

const isRefurbished = (pricing: OfferItemPricing) => {
  return pricing?.variant === 'refurbished';
};

export const mapOfferAndPricingAsGtmItem = ({
  offer,
  selectedPricing,
  productName,
  listName,
  listPosition,
  financeAvailable,
}: {
  offer: Offer;
  selectedPricing: OfferItemOneTimeRecurringPricing;
  productName: string;
  listName?: any;
  listPosition?: any;
  financeAvailable?: any;
}) => {
  const { categorySlug } = offer.offerItems.find((offerItem: OfferItem) => offerItem.type === 'main');

  return {
    planCategory: categorySlug,
    monthlyPrice: selectedPricing?.recurringAmount / 100,
    refurbished: isRefurbished(selectedPricing),
    currency: 'EUR',
    selectedPricing,
    enabled: offer.isLive,
    country: offer.country,
    groupType: offer.groupType,
    bmId: offer.contentSlug,
    productVib: offer.contentSlug,
    productName,
    financeAvailable,
    // if list position and name will be undefined that will NOT harm analytics
    listPosition,
    listName,
  };
};

export const mapOfferAsGtmItem = (offerData: Offer, productName: string) => {
  const { pricings, categorySlug, groupType } = offerData.offerItems.find(
    (offerItem: OfferItem) => offerItem.type === 'main'
  );

  const theLowestRentingPrice = pricings.sort((a, b) => a.recurringAmount - b.recurringAmount)?.[0]?.recurringAmount;

  return {
    planCategory: categorySlug,
    currency: 'EUR',
    enabled: offerData.isLive,
    country: offerData.country,
    groupType: groupType,
    bmId: offerData.contentSlug,
    productVib: offerData.contentSlug,
    monthlyPrice: theLowestRentingPrice / 100,
    updateDate: offerData.updateDate,
    refurbished: isRefurbished(pricings[0]),
    financeAvailable: [OfferContractType.Financing, OfferContractType.RentalAndFinancing].includes(
      offerData.contractType
    ),
    productName,
  };
};

export const mapCartItemsWithOfferAsGtmItem = (cart, plans) => {
  return cart?.items?.map(item => {
    const mainProductName = plans && plans[item.offerItem?.contentSlug?.toLowerCase()]?.name;
    const accessoryProductName = item.offerItem?.contentSlug
      .toLowerCase()
      .replace(/-(de|nl)-/, ' ')
      .replace(/-/g, ' ');

    return {
      planCategory: item.offerItem?.plan?.category?.name,
      monthlyPrice: item.pricing?.amount ? item?.pricing?.amount / 100 : item?.pricing?.recurringAmount / 100,
      refurbished: isRefurbished(item.pricing),
      currency: 'EUR',
      selectedPricing: item.pricing,
      cartCouponCode: cart?.coupon?.code,
      enabled: item.pricing.isActive,
      country: cart?.country || item.country,
      groupType: item.offerItem?.plan?.category?.groupType,
      bmId: item.offerItem?.contentSlug,
      productVib: item.offerItem?.contentSlug,
      productName: item.offerItem?.type === 'additional' ? accessoryProductName : mainProductName,
      association: item.offerItem?.type === 'additional' ? item.offerItem?.contentSlug : null,
      availability: item.pricing?.isActive,
    };
  });
};

export const mapOfferToOnClickEventItem = (offer: Offer, productName: string) => {
  const { categorySlug, pricings, isAtLeastOneVariantAvailable } = offer.offerItems.find(
    (offerItem: OfferItem) => offerItem.type === 'main'
  );
  const theLowestRentingPrice = pricings.sort((a, b) => a.recurringAmount - b.recurringAmount)?.[0]?.recurringAmount;

  return [
    {
      item_id: offer.contentSlug,
      item_name: productName,
      planCategory: categorySlug,
      price: theLowestRentingPrice,
      financeAvailable: [OfferContractType.Financing, OfferContractType.RentalAndFinancing].includes(
        offer.contractType
      ),
      availability: isAtLeastOneVariantAvailable,
    },
  ];
};
