const dayjs = require('dayjs');

const TERM_REGEX = /(\d+) (years|months|jaar|maanden)/;

const yearNameList = ['years', 'jaar'];

const termPartsToMonths = (amount, unit) => {
  const months = yearNameList.includes(unit) ? 12 : 1;

  return amount * months;
};

const termToParts = term => {
  // match[1] = unit, match[2] = term
  const match = term && term.match(TERM_REGEX);

  if (!match) {
    throw Error(`Not a valid term ${term}`);
  }

  return { amount: parseInt(match[1], 10), unit: match[2] };
};

const termToMonths = term => {
  const parts = termToParts(term);

  return termPartsToMonths(parts.amount, parts.unit);
};
module.exports = {
  termToMonths,
  sortOffersByContractDuration(offers) {
    offers.sort((a, b) => {
      const firstOfferMonthsAmount = termPartsToMonths(a.termAmount, a.termUnit) || 0;
      const secondOfferMonthsAmount = termPartsToMonths(b.termAmount, b.termUnit) || 0;

      return firstOfferMonthsAmount - secondOfferMonthsAmount;
    });

    return offers;
  },
};
