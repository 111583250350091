import { StoryData } from 'storyblok-js-client';

import { Country, GroupType } from './common.types';
import { FrontendFinancialProduct } from './frontend-financial-product.types';
import { Plan, PlanClass, PricingVariant } from './plan.types';

export enum OfferContractType {
  Rental = 'Rental',
  Financing = 'Financing',
  RentalAndFinancing = 'RentalAndFinancing',
}

export interface Offer {
  id: string;
  contentSlug: string;
  internalName: string;
  isLive: boolean;
  groupType: GroupType; //TODO: OFFER remove and rely on mainItem.groupType
  offerItems: OfferItem[];
  country: Country;
  contractType: OfferContractType;
  updateDate?: string;
  financial?: FrontendFinancialProduct;
}

export interface OfferItem {
  id: string;
  isLive: boolean;
  contentSlug: string;
  categorySlug: string;
  pricingStrategy: OfferItemPricingStrategyType;
  type: 'main' | 'additional';
  offer: Offer;
  isAtLeastOneVariantAvailable: boolean;
  isDemandTestCategory: boolean;
  plan?: Plan;
  pricings?: OfferItemPricing[];
  groupType: GroupType;
  content: StoryData['content'];
  requirementType: 'optional' | 'mandatory';
  isHiddenFromCustomer: boolean;
  class: PlanClass;
}

export enum OfferItemPricingStrategyType {
  Standard = 'standard',
  Override = 'override',
}

export type OfferItemPricing = OfferItemRecurringPricing | OfferItemOneTimePricing | OfferItemOneTimeRecurringPricing;

interface OfferItemPricingBase {
  id: string;
  currency: string;
  isReferenceAmountActive: boolean;
  variant: PricingVariant;
  isAvailable: boolean;
  months: number;
  term: string;
}

export enum BillingType {
  Recurring = 'recurring',
  OneTime = 'one_time',
  OneTimeRecurring = 'one_time_recurring',
}

export interface OfferItemRecurringPricing extends OfferItemPricingBase {
  billingType: BillingType.Recurring;
  recurringAmount: number;
  referenceRecurringAmount?: number;
  oneTimeAmount: 0;
  referenceOneTimeAmount?: 0;
}

export interface OfferItemOneTimePricing extends OfferItemPricingBase {
  billingType: BillingType.OneTime;
  oneTimeAmount: number;
  referenceOneTimeAmount?: number;
  recurringAmount: 0;
  referenceRecurringAmount?: 0;
}

export interface OfferItemOneTimeRecurringPricing extends OfferItemPricingBase {
  billingType: BillingType.OneTimeRecurring;
  recurringAmount: number;
  oneTimeAmount: number;
  referenceRecurringAmount: number;
  referenceOneTimeAmount?: number;
}

export type OfferItemWithStoryblokContent = {
  storyblokContent: StoryData & {
    _uid: string;
    component: string;
    energyClassValue?: string;
    plan?: StoryData;
    featuresToDisplay?: string[];
  };
  offerData: Offer & {
    isAtLeastOneVariantAvailable?: boolean;
    lowestPrice?: number;
  };
};
